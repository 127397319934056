<template>
  <sidenav orientation='vertical' :class='curClasses' style='border: none;'>
    <div v-if='fadeMenu' ref='fadeMenu' class='backgroundFade'></div>
    <!-- MENU -->
    <div>
      <block-ui :loading='menuLoading'></block-ui>

      <div v-show='noMenuItems'>
        <sidenav-header style='color: white' class='font-weight-bold'>Nenhum menu disponível</sidenav-header>
      </div>

      <perfect-scrollbar
        ref='scrollDiv'
        :options='{ wheelPropagation: true }'
        class='mobileBox'
        id='scrollDiv'
      >
        <div class='mobileBox'>
          <div class='sidenav-inner'>
            <div
              v-for='(elem, index) in sideMenuItems'
              :key='elem.title'
              class='w-100 sidenav-item-wrapper'
              :class="elem.destacado ? 'menu-destacado': 'menu-padrao'"
            >
              <div
                class='sidenav-item'
                v-if='elem.subitems === null'
                :class="{'active': isActive(null, elem.link, index)}"
                :id="'div-menu-'+elem.title.replace(/\s+/g,'-').toLowerCase()"
              >
                <a
                  :id="'menu-'+ elem.title.replace(/\s+/g,'-').toLowerCase()"
                  :href='elem.link'
                  @click.stop.prevent='onClick(elem)'
                  class='sidenav-link'
                >
                  <i class='sidenav-icon' :class='elem.icon'></i>
                  <div>
                    <span>{{elem.title}}</span>
                    <div v-if='elem.destacado'>{{elem.title.split(' ')[0]}}</div>
                  </div>
                  <b-badge
                    v-if="elem.title === 'Tarefas' && qtdTarefas > 0"
                    class='ml-auto'
                    variant='segfy'
                  >{{qtdTarefas}}</b-badge>
                </a>
              </div>

              <div
                class='sidenav-item group-item'
                v-else
                :class="{'active open': isActive(elem.rota, null, index, true)}"
                :name='elem.rota'
                :id="'div-menu-'+elem.title.replace(/\s+/g,'-').toLowerCase()"
              >
                <a
                  :id="'menu-'+ elem.title.replace(/\s+/g,'-').toLowerCase()"
                  href='javascript:void(0)'
                  @click='onParentMenuClick($event)'
                  class='sidenav-link sidenav-toggle'
                >
                  <i class='sidenav-icon' :class='elem.icon'></i>
                  <div>
                    <span>{{elem.title}}</span>
                  </div>
                </a>
                <div class='sidenav-menu d-flex'>
                  <div
                    class='sidenav-item'
                    v-for='subitem in elem.subitems'
                    :key='subitem.title'
                    :class="{'active': isActive(elem.rota, subitem.link, index)}"
                  >
                    <a
                      :id="'submenu-'+ subitem.title.replace(/\s+/g,'-').toLowerCase()"
                      :href='subitem.link'
                      @click.stop.prevent='onClick(subitem)'
                      class='sidenav-link'
                    >
                      <div>{{subitem.title}}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <ModalContratarMenu ref="modalContratarMenu"></ModalContratarMenu>      
    </div>
    <!-- /MENU -->
  </sidenav>  
</template>

<style lang='scss'>
    @import '../../../style/common.scss';

    .sidenav-inner.ps.ps--active-y > .ps__rail-y{
        z-index: 500;
        margin-right: 0px !important;
    }
    // .sidenav.layout-sidenav,
    .sidenav-item-wrapper .sidenav-item,
    .sidenav.layout-sidenav .sidenav-link > div > span,
    .sidenav.layout-sidenav .sidenav-link > div > div,
    .sidenav.layout-sidenav .sidenav-toggle::after,
    .layout-sidenav .sidenav-item-wrapper{
        -webkit-transition: all 0.25s ease-in-out !important;
        -moz-transition: all 0.25s ease-in-out !important;
        -o-transition: all 0.25s ease-in-out !important;
        transition: all 0.25s ease-in-out !important;
    }
    .sidenav .sidenav-item .sidenav-link,
    .sidenav .sidenav-item .sidenav-link span:not(.badge),
    .sidenav .sidenav-item .sidenav-link div{
        color:#333333;
    }
    .sidenav .sidenav-item.active > .sidenav-link span,
    .sidenav .sidenav-item.active > .sidenav-link div{
        font-weight: bold;
    }

    .sidenav .sidenav-inner{
        max-height: calc(100vh - 72px);
    }

    .sidenav.layout-sidenav .menu-padrao {
        border-top: 1px solid #DCDCDC;
    }
    .sidenav.layout-sidenav .menu-padrao ~ .menu-padrao {
        border-top: none;
    }

    .sidenav-item-wrapper .sidenav-item > .sidenav-link {
        padding: 0.7rem 0.5rem ;
        // border-bottom: 1px solid transparent;
    }

    .sidenav-item-wrapper > .sidenav-item > .sidenav-link:hover,
    .sidenav-item-wrapper > .sidenav-item.active > .sidenav-link{
        background-color:$Grey_4 !important;
        // border-bottom:1px solid rgba(0,0,0,0.025);

        .sidenav-link > div{
            color:#212121;
        }
    }

    .backcolor-segfy {
        background-color: $segfy_1;
        background-color: $segfy_1;
    }

    .sidenav-item.active > .sidenav-link .sidenav-icon {
        color: $segfy_1;
    }

    .sidenav-menu .sidenav-link{
        padding-left:4rem !important;
    }

    .sidenav-item-wrapper .sidenav-item.open:hover{
        background-color:transparent;
    }

    .sidenav-item-wrapper .sidenav-item.active.open .sidenav-link .sidenav-icon
    {
        color: $segfy_1;
    }

    .sidenav-item-wrapper .sidenav-item.open{
        .sidenav-item:hover  > .sidenav-link,
        .sidenav-item.active > .sidenav-link{
            background-color:rgba(0,0,0,0.05) !important;
            // border-bottom:1px solid rgba(0,0,0,0.025);
        }
    }

    .sidenav.layout-sidenav .sidenav-link > div {
        white-space: nowrap;
    }

    .layout-sidenav .sidenav-link .sidenav-icon {
        text-align: center;
        font-size:20px;
        width: 44px !important;
        display: inline-block;
    }

    .sidenav-toggle::after{
        -webkit-transform: translateY(-50%) rotate(225deg) !important;
        transform: translateY(-50%) rotate(225deg) !important;
    }
    .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle::after {
        -webkit-transform: translateY(-50%) rotate(315deg) !important;
        transform: translateY(-50%) rotate(315deg) !important;
    }

    .sidenav.layout-sidenav .sidenav-link > div > div{
        opacity: 0;
        position: absolute;
        bottom: 1px;
        left: 0px;
        width: 74px;
        font-size: 10px;
        text-align: center;
    }

    .sidenav-item .sidenav-menu{
        max-height:0px;
        overflow: hidden !important;
        padding:0px;
        
        -webkit-transition: max-height 0.25s ease-in-out !important;
        -moz-transition: max-height 0.25s ease-in-out !important;
        -o-transition: max-height 0.25s ease-in-out !important;
        transition: max-height 0.25s ease-in-out !important;
    }
    .sidenav-item.open .sidenav-menu{
        max-height:500px;
    }

    @media (min-width: 1199px) {
        .layout-collapsed .layout-sidenav {
            width: 4.625rem !important;
            margin-right: 0rem !important;
            -webkit-transform: none !important;
            transform: none !important;
        }
        
        .layout-collapsed .sidenav-item .sidenav-toggle::after,
        .layout-collapsed .sidenav-item-wrapper.menu-padrao,
        .layout-collapsed .layout-sidenav .sidenav-link > div > span {
            opacity: 0;
        }
        .layout-collapsed .layout-sidenav .sidenav-link > div > div{
            opacity: 1;
        }
    }
</style>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from '@/vendor/libs/sidenav'
import BlockUI from '../../../components/common/blockUI'
import PerfectScrollbar from '@/vendor/libs/perfect-scrollbar/PerfectScrollbar'
import { getMenu, getRestricaoHorarioAtiva } from '../../../components/notificacao/methods.js'
import { logoffUser } from '../../../components/auth/methods.js'
import { getTarefas } from '../../../components/tarefas/methods.js'
// import Driverjs from 'driver.js'
import 'driver.js/dist/driver.min.css'
import { mapState } from 'vuex'
import ModalContratarMenu from '../../../components/common/modalContratarMenu'

// const driver = new Driverjs()

export default {
  name: 'app-layout-sidenav',
  components: {
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    'block-ui': BlockUI,
    PerfectScrollbar,
    ModalContratarMenu
  },

  data: function () {
    return {
      sideMenuItems: [],
      noMenuItems: false,
      menuLoading: false,
      qtdTarefas: 0,
      ultimaChecagemRestricao: new Date(),
      novaAssinatura:
        new Date(this.$store.state.auth.dataAssinatura) >=
        new Date('2019-09-27')
    }
  },
  created () {
    this.menuLoading = true
    this.requestMenuItems()

    this.usuarioLiberadoCotarSaude = this.$store.state.auth.acessoCotacaoSaude
  },

  mounted: function () {
    // Vamos pedir para o servidor as opções disponíveis para o usuário, no menu
    // Isso varia conforme o nível de permissão
    let val = this.stepOnboarding
    this.actionsByStep(val)
  },
  watch: {
    stepOnboarding: function (val) {
      this.actionsByStep(val)
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (
        bg.indexOf(' sidenav-dark') !== -1 ||
        bg.indexOf(' sidenav-light') !== -1
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + 'layout-sidenav'
    },
    ...mapState('newOnboarding', {
      stepOnboarding: 'step',
      fadeMenu: 'fadeMenu'
    })
  },

  methods: {
    actionsByStep (val) {
      if (val === 3) {
        this.unlockMenu('all')
        let element = document.getElementById('menu-configurações')
        setTimeout(() => {
          if (!element.parentElement.classList.contains('open')) element.click()
          this.unlockMenu('none')
          window.Vue.onboarding.driver.exec('#submenu-logins-seguradoras', {
            title: 'O primeiro passo é configurar o login e senha das CIAs seguradoras.',
            description: 'Clique em "Login Seguradoras" para que o Segfy possa fazer cotações, buscas e consultas para você.',
            position: 'right',
            showButtons: false
          })
        }, 200)
        window.scrollTo(0, 0)
        this.unlockMenu('none')
      } else if (val === 5) { 
        this.unlockMenu('all')
        setTimeout(() => {
          let element = document.getElementById('menu-propostas-e-apólices')
          if (!element.parentElement.classList.contains('open')) {
            element.click()
          }
          this.unlockMenu('none')
          window.Vue.onboarding.driver.exec('#submenu-busca-de-documentos', {
            title:
              'Agora o Segfy pode buscar documentos para você no portal das Seguradoras',
            description:
              'Buscamos uma vez por dia todos os documentos disponibilizados pelas seguradoras',
            position: 'right',
            showButtons: false
          })
        }, 150)
       } else if (val === 8) {
        this.unlockMenu('none')
        window.Vue.onboarding.driver.exec('#submenu-importar-pdf-', {
          title: 'Você pode também importar os documentos que já estão salvos no seu computador.',
          position: 'right',
          showButtons: false
        })
       } else if (val === 10) {
        this.unlockMenu('all')
        setTimeout(() => {
          this.unlockMenu('none')
          window.Vue.onboarding.driver.exec('#menu-multicálculo', {
            title: 'Acompanhar funil de vendas.',
            description: 'O próximo passo é utilizar o HFy e fazer sua primeira cotação pelo Segfy.',
            position: 'right',
            showButtons: false
          })
        }, 300)
      } else if (val === 13) {
        this.unlockMenu('none')
        window.Vue.onboarding.driver.exec('#menu-home', {
          title:
            'Por fim, clique em "Home" para ver a sua página inicial no Segfy.',
          position: 'right',
          showButtons: false
        })
     } else if (val === 16) {
        this.unlockMenu('all')
        setTimeout(() => {
          let element = document.getElementById('menu-ajuda')
          if (!element.parentElement.classList.contains('open')) element.click()
          this.unlockMenu('none')
          window.scrollTo(0, 0)
          window.Vue.onboarding.driver.exec(
            '#div-menu-ajuda',
            {
              title:
                'Por fim, basta consultar a Central de Ajuda e Solicitar Suporte sempre que tiver dúvidas.',
              description:
                'Possuímos diversos artigos explicativos, e nossa equipe está disposta a te atender.',
              position: 'right',
              showButtons: true,
              closeBtnText: 'OK, Entendi!'
            },
            false
          )
        }, 200)
      } else if (val === 17) {
        this.unlockMenu('all')
        this.dismissEveryFuckingBlockHighlighted('all')
      }
    },
    unlockMenu (condition) {
      document.getElementById('scrollDiv').style.pointerEvents = condition
      document.getElementById('navbarMenuTopoLayout').style.pointerEvents = condition
      if (condition === 'all') condition = 'text'
      document.getElementById('scrollDiv').style.userSelect = condition
      document.getElementById('navbarMenuTopoLayout').style.userSelect = condition
    },
    dismissEveryFuckingBlockHighlighted (condition) {
      document.getElementById('submenu-logins-seguradoras').style.pointerEvents = condition
      document.getElementById('submenu-busca-de-documentos').style.pointerEvents = condition
      document.getElementById('submenu-importar-pdf-').style.pointerEvents = condition
      document.getElementById('submenu-orçamentos').style.pointerEvents = condition
      document.getElementById('menu-home').style.pointerEvents = condition
      document.getElementById('div-menu-ajuda').style.pointerEvents = condition
      if (condition === 'all') condition = 'text'
      document.getElementById('submenu-logins-seguradoras').style.userSelect = condition
      document.getElementById('submenu-busca-de-documentos').style.userSelect = condition
      document.getElementById('submenu-importar-pdf-').style.userSelect = condition
      document.getElementById('submenu-orçamentos').style.userSelect = condition
      document.getElementById('menu-home').style.userSelect = condition
      document.getElementById('div-menu-ajuda').style.userSelect = condition
    },
    getDivider (menu) {
      if (!menu.destacado && this.divider === false) {
        menu.divider = true
      }
      menu.divider = false
    },
    isActive (rota, link, index, isGroup) {
      let path = this.$route.path.toLowerCase()
      let linkLower = link ? link.toLowerCase() : ''
      let active = false
      if (path === '/home' && index === 0) {
        active = true
      } else if (!isGroup) {
        active = path === (link ? linkLower : '')
      }

      if (active) {
        let menus = document.getElementsByClassName('sidenav-item group-item')
        if (menus && menus.length > 0) {
          for (let i = 0; i < menus.length; i++) {
            menus[i].classList.remove('active')
            menus[i].classList.remove('open')
          }
        }
        setTimeout(function () {
          let parentGroup = document.getElementsByName(rota)
          if (parentGroup.length > 0) {
            parentGroup[0].classList.add('active')
            parentGroup[0].classList.add('open')
          }
        }, 100)
      }

      return active
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },
    requestMenuItems (serverUrl) {
      getMenu()
        .then(resp => {
          if (resp.success) {
            this.sideMenuItems = resp.data
            let foundTarefa = this.sideMenuItems.find(function (element) {
              return element.title === 'Tarefas'
            })
            if (foundTarefa) {
              getTarefas()
                .then(resp => {
                  if (resp.success) {
                    this.qtdTarefas = resp.data
                  }
                })
                .catch(() => {})
            }
          } else {
            this.noMenuItems = true
          }
          this.menuLoading = false
        })
        .catch(() => {
          this.noMenuItems = true
          this.menuLoading = false
        })
    },
    onClick (menu) {
      if (menu.link === '/multicalculo/orcamento-multicalculo-saude' && this.usuarioLiberadoCotarSaude === false) {
          this.$refs.modalContratarMenu.openModal()
      } else {
        if (menu.link.indexOf('http') >= 0) {
        let win = window.open(menu.link, '_blank')
        win.focus()
      } else {
        // antes de fazer a rota, vamos ver se o usuário ta com o horário bloqueado,
        // mas vamos ter um tempo entre as chamadas para não ficar disparando chamada a toa para api :)
        if ((new Date() - this.ultimaChecagemRestricao) <= 5 * 60000) {
          this.$router.push(this.setURL(menu.link))
        } else {
          getRestricaoHorarioAtiva().then(resp => {
            this.ultimaChecagemRestricao = new Date()

            if (resp.success) {
              if (resp.data) {
                logoffUser().then(o => {
                  if (process.env.VUE_APP_FAYE !== 'local') {
                    window.Vue.hub.connectionFaye.pub(
                      'logoff',
                      window.Vue.hub.connectionFaye.uid
                    )
                  }

                  this.logoutLegado()

                  window.Vue.onboarding.driver.reset()

                  window.Vue.$store._actions['auth/forgetUser'][0]()
                  window.Vue.$noty.error('O seu usuário foi desconectado por conta da restrição do horário em seu cadastro.')
                  window.open(`${process.env.VUE_APP_SSO_FRONT}/login?callback=${process.env.VUE_APP_SERVER}`, '_self')
                })
              } else {
                this.$router.push(this.setURL(menu.link))
              }
            }
          })
        }
      }
      }
    },
    onParentMenuClick (event) {
      event.preventDefault()
      event.stopPropagation()

      let menu = event.currentTarget
      menu.parentElement.classList.toggle('open')

      setTimeout(() => {
        let openedMenus = document.getElementsByClassName(
          'sidenav-item group-item open'
        )
        if (openedMenus && openedMenus.length > 0) {
          if (menu.parentElement.classList.contains('open') >= 0) {
            var link = menu.parentElement.querySelectorAll('.sidenav-link')[1]
            this.scrollToElement(
              link,
              this.$refs.scrollDiv.$el.querySelector('.sidenav-inner'),
              500,
              100
            )
          }
        } else {
          this.scrollTop(
            0,
            500,
            this.$refs.scrollDiv.$el.querySelector('.sidenav-inner')
          )
        }
      }, 200)
      let menus = document.getElementsByClassName(
        'sidenav-item group-item open'
      )
      if (menus && menus.length > 0) {
        for (let i = 0; i < menus.length; i++) {
          if (menus[i] !== menu.parentElement) {
            menus[i].classList.remove('open')
          }
        }
      }
    }
  }
}
</script>
